import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"

import { Content } from "page_components/privacy-policy"

const PrivacyPolicy = ({ data, location }) => {
  const pageData = data.allWpPage.nodes[0]

  return (
    <Layout
      seo={{
        title: pageData?.title,
      }}
      location={location}
    >
      <Content pageData={pageData} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDozODk=" } }) {
      nodes {
        id
        title
        content
      }
    }
  }
`

export default PrivacyPolicy
