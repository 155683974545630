import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

import Button from "components/Button"

const Content = ({ pageData }) => {
  const { t } = useLocale()

  return (
    <section className="privacy-policy-content">
      <div className="container-fluid">
        <div className="privacy-policy-content__wrapper">
          <h2>{pageData?.title}</h2>
          <div className="row">
            <div
              className="col-xl-7 offset-xl-1"
              dangerouslySetInnerHTML={{ __html: pageData?.content }}
            ></div>
          </div>
        </div>

        <div className="privacy-policy-content__cta">
          <Button to={t("/")}>{t("Powrót")}</Button>
        </div>
      </div>
    </section>
  )
}

export default Content
